import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'

const OfferingsPage = () => (
  <Layout>
    <Seo
      title="Familienberatung"
      description="Ich begleite sie als Familie bei Krisen und Konflikten."
      keywords={[
        'Familienberatung',
        'Famiile',
        'Trennung',
        'Scheidung',
        'Beratung',
        'Kinder',
        'Konflikte',
        'Erziehung',
        'Eltern',
      ]}
    />
    <article>
      <div className="fl w-100 w-50-ns pr2">
        <StaticImage
          src={'../images/familie.jpg'}
          alt="Familie"
          imgClassName="mb2 mr2"
        />
      </div>
      <div className="fl w-100 w-50-ns">
        <h1>Familienberatung</h1>
        <p>
          Familie entwickelt sich beständig weiter, indem eine durchlebte
          Familienphase durch eine neu zu bewältigende abgelöst wird. Wünsche
          und Bedürfnisse der Familienmitglieder müssen aufeinander abgestimmt
          und Rechte und Pflichten manchmal neu ausgehandelt werden.
        </p>
        <p>Hier einige Beispielanlässe: </p>
        <ul>
          <li>
            Eltern erkennen die Notwendigkeit, im Hinblick auf die Erziehung der
            Kinder eine gemeinsame Linie zu finden und zu vertreten
          </li>
          <li>
            Die eingeschränkte oder zum Erliegen gekommene Kommunikation
            zwischen bestimmten Familienmitgliedern soll wieder in Gang gebracht
            werden
          </li>
          <li>
            Einem Kind soll geholfen werden, aus einer negativen
            Rollenzuschreibung herauszufinden
          </li>
          <li>
            An Fortsetzungsfamilien sind hohe kommunikative und organisatorische
            Anforderungen gestellt. Beratung kann helfen, in turbulenten Phasen
            konsensuelle Lösungen zu finden.
          </li>
          <li>
            Im geschützten Raum einer Beratung kann ein tabuisiertes
            Familienthema zur Sprache gebracht werden
          </li>
        </ul>
      </div>
    </article>
  </Layout>
)

export default OfferingsPage
